import { NavLink } from 'react-router-dom'

const TabBarList = [
  {
    name: '待办',
    path: '/',
    icon: 'icon-pending'
  },
  {
    name: '工作台',
    path: '/work',
    icon: 'icon-workbench'
  },
  {
    name: '我的',
    path: '/mine',
    icon: 'icon-mine'
  }
]

function TabBar() {
  return (
    <div className={ 'tab-bar' }>
      <div className={ 'tab-bar-content hairline-top' }>
        {
          TabBarList.map((tabBar, index) => {
            return (
              <NavLink
                style={ ({ isActive }) => ({ color: isActive ? '#FF6600' : '#323233' }) }
                className={ 'tab-bar-item' }
                key={ index }
                replace={ true }
                to={ tabBar.path }>
                <i className={ tabBar.icon + ' iconfont' } />
                <span>{ tabBar.name }</span>
              </NavLink>
            )
          })
        }
      </div>
    </div>
  )
}

export default TabBar
