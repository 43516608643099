function BackIcon() {
  return (
    <>
      <svg width="6.4vw" height="6.4vw" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" fill="white" fillOpacity="0.01"/>
        <path d="M31 36L19 24L31 12" stroke="#646566" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  )
}

export default BackIcon
