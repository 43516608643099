import { lazy } from 'react'
const orderParticulars = lazy(() => import(/* webpackChunkName: "orderParticulars" */ '../../pages/orderParticulars'))
const userAgreement = lazy(() => import(/* webpackChunkName: "orderParticulars" */ '../../pages/userAgreement'))
const authenticationCode = lazy(() => import(/* webpackChunkName: "orderParticulars" */ '../../pages/authenticationCode'))

const RoutePage = [
  {
    name: '订单详情',
    path: '/orderParticulars',
    auth: true,
    // navBar: true,
    element: orderParticulars
  },
  {
    name: '用户协议',
    path: '/userAgreement',
    auth: true,
    // navBar: true,
    element: userAgreement
  },
  {
    name: '短信验证码',
    path: '/note',
    auth: true,
    // navBar: true,
    element: authenticationCode
  }
] as RouteData[]

export default RoutePage
