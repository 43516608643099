function SuspenseLoading() {
  return (
    <div className={ 'suspense-loading' }>
      <span style={ {animationDelay: '0s'} }>l</span>
      <span style={ {animationDelay: '0.1s'} }>o</span>
      <span style={ {animationDelay: '0.2s'} }>a</span>
      <span style={ {animationDelay: '0.3s'} }>d</span>
      <span style={ {animationDelay: '0.4s'} }>i</span>
      <span style={ {animationDelay: '0.5s'} }>n</span>
      <span style={ {animationDelay: '0.6s'} }>g</span>
    </div>
  )
}

export default SuspenseLoading
