import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import BackIcon from '../../assets/icons/svg/BackIcon'

interface Props {
  children?: any
}

function NavBar(props: Props) {
  const { children } = props

  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useState('')


  const handleBack = debounce(() => {
    navigate(-1)
  }, 160)

  useEffect(() => {
    setTitle(document.title)
  }, [location.key])

  return (
    <div className={ 'nav-bar' }>
      <div className={ 'nav-bar-content hairline-bottom' }>
        <div className={ 'nav-bar-left touch-active' } onClick={ handleBack }>
          <BackIcon />
        </div>
        <div className={ 'nav-bar-title' }>
          { title }
        </div>
        <div className={ 'nav-bar-right' }>
          { children }
        </div>
      </div>
    </div>
  )
}

export default NavBar
