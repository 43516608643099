import { ReactNode, Suspense, useEffect } from 'react'
import NavBar from './NavBar'
import TabBar from './TabBar'
import SuspenseLoading from './SuspenseLoading'
import './main.scss'

interface Props {
  children: ReactNode
  navBar?: boolean
  tabBar?: boolean
}

function MainLayouts(props: Props) {
  const { children, navBar, tabBar } = props

  useEffect(() => {
    document.body.style.setProperty('--padding-top-main-layout', navBar ? '12.26667vw' : '0')
    document.body.style.setProperty('--padding-bottom-main-layout', tabBar ? '13.33333vw' : '0')
  }, [navBar, tabBar])

  return (
    <div id={ '__main_layout' }>
      {
        navBar
          ?
          <NavBar />
          :
          null
      }
      <div className={ 'main' }>
        <Suspense fallback={ <SuspenseLoading /> }>
          { children }
        </Suspense>
      </div>
      {
        tabBar
          ?
          <TabBar />
          :
          null
      }
    </div>
  )
}

export default MainLayouts
