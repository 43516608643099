export const delayEvent = (fn: () => void) => {
  debounce(fn, 160)()
}

export function MaskMobile (mobile: string) {
  const reg = /(\d{3})\d{4}(\d{4})/
  return mobile.replace(reg, '$1****$2')
}

export function formatTime(time: any, format: any) {
  time = typeof (time) === "number" ? time : (time instanceof Date ? time.getTime() : parseInt(time));
  if (isNaN(time)) return null;
  if (typeof (format) !== 'string' || !format) format = 'yyyy-MM-dd hh:mm:ss';
  let _time = new Date(time);
  // eslint-disable-next-line no-useless-escape
  time = _time.toString().split(/[\s\:]/g).slice(0, -2);
  time[1] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'][_time.getMonth()];
  let _mapping:any = {
    MM: 1,
    dd: 2,
    yyyy: 3,
    hh: 4,
    mm: 5,
    ss: 6
  };
  return format.replace(/([Mmdhs]|y{2})\1/g, (key: any) => time[_mapping[key]]);
}
export const getCode = () => {
  let url = window.location.href;
  let obj: any = {};
  let reg = /[?&][^?&]+=[^?&]+/g;
  let arr = url.match(reg);
  if (arr) {
    arr.forEach((item) => {
      let tempArr = item.substring(1).replace('=', '|').split('|');
      let key = decodeURIComponent(tempArr[0]);
      let val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
}